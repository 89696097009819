import React from 'react'
import s from 'styled-components'

import { getLanguage } from '../../locale'
import Carousel from '../carousel'
import CardPanel from '../card/panel'
import PackageTagHot from '../../images/packgate-tag-hot.svg'
import { BoxDetail } from '../box'

const INIT_COL_DATA = {
  type: '-',
  price: '0',
  currency: 'THB',
  perAt: 'per event',
  desc: ['0 invitees per event'],
}

const DATA_DEMO = [
  [
    {
      type: 'Free',
      package_id: 1,
      price: '0',
      currency: 'THB',
      perAt: 'per event',
      desc: ['20 invitees per event'],
      bgClassType: 1,
      isHot: false,
      picture_en: '',
    },
    {
      type: 'Standard',
      package_id: 2,
      price: '500',
      currency: 'THB',
      perAt: 'per event',
      desc: [
        '100 invitees per event',
        'Facebook Notification',
        'Online Payment',
      ],
      bgClassType: 2,
      isHot: true,
      picture_en: '',
    },
    {
      type: 'Premium',
      package_id: 3,
      price: '1,000',
      currency: 'THB',
      perAt: 'per event',
      desc: [
        '500 invitees per event',
        'Facebook Notification',
        'Online Payment',
      ],
      bgClassType: 3,
      isHot: false,
      picture_en: '',
    },
  ],
]

const BadgeImage = s.img`position: absolute;top: 0;left: 0;width: 60px !important;height: 60px;` //bg-${data.bgClassType}
const BoxWrapper = s.div`
color : ${({ color }) => color} !important;

& h2, & small , & h5 {
  color : ${({ color }) => color} !important;
}

& .__card-box-botton > button {
  border : 1px solid ${({ color }) => color} !important;
  width: 70%;
}
`
const CardItem = ({ data = INIT_COL_DATA, onChoose, text }) => {
  const colorText = data.text_color || '#FFFFFF'
  let badge
  let picture
  let description

  if (getLanguage().lang === 'TH') {
    badge = data.badge_th
    picture = data.picture_th
    description = data.description_th
  } else {
    badge = data.badge_en
    picture = data.picture_en
    description = data.description_en
  }

  if (description.length > 100) {
    description = description.substring(0, 100) + '...'
  }

  return (
    <BoxWrapper
      className={`__card-box `}
      style={{
        backgroundImage: `url(${picture})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        position: 'relative',
      }}
      color={colorText}
    >
      {badge && <BadgeImage src={badge + '?sz=m'} alt="" />}
      <div className="__card-box-header">
        <h2>{data.type}</h2>
      </div>
      <div className="__card-box-price">
        <h2>
          {data.price}
          <small>{data.currency}</small>
        </h2>
        <h5 style={{ color: colorText }}>{data.perAt}</h5>
      </div>
      <div className="__card-box-description">
        {data.desc.map((content, contentIndex) => (
          <p key={`Content-Index-${contentIndex}`}>{content}</p>
        ))}
        <p>{description}</p>
      </div>
      <div className="__card-box-botton">
        <button onClick={onChoose} style={{ color: colorText }}>
          {text}
        </button>
      </div>
      {data.isHot && (
        <img src={PackageTagHot} alt="" className="__card-bog-tag-hot" />
      )}
    </BoxWrapper>
  )
}

export default class BoxCreateEvent extends React.Component {
  state = {
    Components: [],
  }

  componentDidMount() {
    this.setComponentList()
  }

  setComponentList = () => {
    const arr = []
    const { data = DATA_DEMO, onChoose, textChoose = 'Choose' } = this.props

    data.map((row, rowIndex) => {
      arr.push(
        <div
          className="__box-row box-carousel-create-events"
          key={`box-create-events-row-${rowIndex}`}
        >
          {row &&
            row.map((col, colIndex) => (
              <div
                className="__box-col"
                key={`box-create-events-row-${rowIndex}-col-${colIndex}`}
              >
                <div style={{ padding: 10 }}>
                  <CardItem
                    data={col}
                    onChoose={() => {
                      let tempjoinType = parseInt(col.price) <= 0 ? 'free' : 'paid'
                      let tempEventInfo = {
                        package_id: col.package_id,
                        typeBuy: 'buy_package',
                        eventID: '',
                        joinType: tempjoinType,
                        description_en: col.description_en,
                        description_th: col.description_th,
                        price: col.price
                      }
                      onChoose(tempEventInfo)
                    }
                  }
                    text={textChoose || 'Choose'}
                  />
                </div>
              </div>
            ))}
        </div>
      )
      return row
    })
    this.setState({ Components: arr })
  }

  render() {
    return (
      <BoxDetail>
        <div className="box-create-events">
          <div className="__header">
            <CardPanel
              title={this.props.title || 'Create New Events'}
              style={{ backgroundColor: '#FFFFFF' }}
              underlineColor="#FFFFFF"
            />
          </div>
          <div className="__body">
            {this.state.Components.length > 0 && (
              <Carousel
                showEveryTime
                className="is-card-slider is-banner-slider"
              >
                {this.state.Components}
              </Carousel>
            )}
          </div>
        </div>
      </BoxDetail>
    )
  }
}

// export default ({ title = 'Create New Events', data = DATA_DEMO, onChoose, textChoose = 'Choose' }) => (
//   <div className="box-create-events">
//     <div className="__header">
//       <CardPanel title={title} style={{ backgroundColor: '#FFFFFF' }} underlineColor="#FFFFFF"/>
//     </div>
//     <div className="__body">
//       <Carousel showEveryTime>
//         {
//           data.map((row, rowIndex) => (
//             <div className="__box-row" key={`box-create-events-row-${rowIndex}`}>
//               {
//                 row && row.map((col, colIndex) => (
//                   <div className="__box-col" key={`box-create-events-row-${rowIndex}-col-${colIndex}`}>
//                     <div style={{ padding: 10 }}>
//                       <CardItem data={col} onChoose={() => onChoose(col.package_id, 'buy_package')} text={textChoose}/>
//                     </div>
//                   </div>
//                 ))
//               }
//             </div>
//           ))
//         }
//       </Carousel>
//     </div>
//   </div>
// )
