import React from 'react'
import s from 'styled-components'

import { ConvertISODate1, toUpperCaseFirst } from '../../utils/string'
import { Modal, ModalClose, ModalContent, ModalHeader } from './index'

const Body = s.div`text-align:center;padding: 10px;`
const Table = s.table`
  width:100%;
  & > tbody > tr > td {
    padding: 10px;
    text-align: left;
  }
  `
const TdRed = s.td`color:#C14C40;`

function TransactionDetail({ data, locale }) {
  return (
    <Modal id="modalTransactionDetail">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalTransactionDetail"
          title={locale.TRANSACTION_DETAIL}
        />
        <Body>
          {data.id === 0 ? (
            <h1>Loading...</h1>
          ) : (
            <Table>
              <tbody>
                <tr>
                  <td>Transaction ID:</td>
                  <TdRed>{data.id}</TdRed>
                </tr>
                <tr>
                  <td>{locale.FROM}:</td>
                  <TdRed>
                    {data.invitation_receiver_name || '-'} (
                    {data.invitation_receiver_email || '-'})
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.CHANNEL}:</td>
                  <TdRed>
                    {data.payment_method === 'internet_banking'
                      ? 'Internet Banking'
                      : data.payment_method === 'credit_card'
                      ? 'Credit Card'
                      : data.payment_method}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.AMOUNT}:</td>
                  <TdRed>
                    {data.amount} {data.currency.toUpperCase()}
                  </TdRed>
                </tr>
                <tr>
                  <td>{locale.STATUS}:</td>
                  <TdRed>{toUpperCaseFirst(data.status)}</TdRed>
                </tr>
                <tr>
                  <td>{locale.CREATED_DATE}:</td>
                  <TdRed>{ConvertISODate1(data.created_at)}</TdRed>
                </tr>
                <tr>
                  <td>{locale.LAST_UPDATED_DATE}:</td>
                  <TdRed>{ConvertISODate1(data.created_at)}</TdRed>
                </tr>
              </tbody>
            </Table>
          )}
        </Body>
        <ModalClose id="modalTransactionDetail" textClose={locale.CLOSE} />
      </ModalContent>
    </Modal>
  )
}

export default TransactionDetail

TransactionDetail.defaultProps = {
  data: {
    id: 0,
    transaction_type: '',
    name: '',
    email: '',
    payment_method: '',
    amount: '',
    created_at: '',
    status: '',
  },
}
