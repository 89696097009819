import React from 'react'
import s from 'styled-components'

const Box = s.div`
  display: inline-block;
  margin-top: 25px;

  & button, span {
    color: black;
    float: left;
    padding: 4px 12px;
    text-decoration: none;
    border-radius: 4px;
  }

  & button.active {
    background-color: #892A24;
    color: white;
  }

  & button.disabled:hover {
    background-color: #ddd;
    cursor: not-allowed;
  }

  & button.less_than, button.greater_than {
    color: #892A24;
  }

  & button:hover:not(.active):not(.disabled) {
    background-color: #ddd;
    cursor: pointer;
  }
`

class Pagination extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      defaultTotal: 0,
      defaultLimit: 20,
    }
  }

  getTotal = () => parseInt(this.props.total || this.state.defaultTotal)
  getLimit = () => parseInt(this.props.limit || this.state.defaultLimit)
  getCurrent = () => Math.floor(this.props.offset / this.getLimit())

  handleOnClick = (isTag, value) => {
    let current = isTag ? this.getCurrent() + value : value

    this.setState({ current: current })
    this.props.onChange(current * this.getLimit())
  }

  getLessThanTag = isDisabled => {
    return (
      <button
        key="less_than"
        className={'less_than' + (isDisabled ? ' disabled' : '')}
        onClick={isDisabled ? null : event => this.handleOnClick(true, -1)}
      >
        &laquo;
      </button>
    )
  }

  getGreaterThanTag = isDisabled => {
    return (
      <button
        key="greater_than"
        className={'greater_than' + (isDisabled ? ' disabled' : '')}
        onClick={isDisabled ? null : event => this.handleOnClick(true, 1)}
      >
        &raquo;
      </button>
    )
  }

  getValueTag = () => {
    let size = Math.ceil(this.getTotal() / this.getLimit())
    let current = this.getCurrent()
    let texts = []

    size = size === 0 ? 1 : size

    for (let index = 0; index < size; index++) {
      let isActive = index === current
      texts.push(
        <button
          key={index}
          className={isActive ? ' active' : ''}
          onClick={isActive ? null : event => this.handleOnClick(false, index)}
        >
          {index + 1}
        </button>
      )
    }

    return texts
  }

  render() {
    let size = Math.ceil(this.getTotal() / this.getLimit()) - 1
    let current = this.getCurrent()
    let buttons = []

    size = size <= 0 ? 0 : size

    buttons.push(this.getLessThanTag(current === 0))
    buttons.push(this.getValueTag())
    buttons.push(this.getGreaterThanTag(current === size))

    return <Box>{buttons}</Box>
  }
}

export default Pagination
