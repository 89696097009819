import React, { Component } from 'react';
import { Button, Row, Col, Input, Divider } from 'antd';

class TicketCreator extends Component {
    render() {
        return (
            <div>
                {this.props.addTitleTicket.map((data, index) => {
                    return (
                        <div key={`ticketTitle${index}`} style={{marginBottom: '40px'}}>
                        { index != 0 ? <Divider style= {{display: 'block', width: '85%', minWidth: '20%', marginLeft: '3%', marginBottom: '35px'}} orientation='center' /> : null}
                            <Row align="middle" style={{ marginBottom: '8px' }}>
                                <Col style={{ fontWeight: 'bold' }}>Title
                             <span>
                                        <Input
                                            style={{ width: '73%', marginLeft: '10px' }}
                                            size='small'
                                        />
                                    </span>
                                </Col>
                            </Row>
                            <div style={{ marginBottom: '8px' }}>
                                <Row>
                                    <Col style={{ fontWeight: 'bold', marginLeft: '10%' }} span={12}>
                                        Subtitle
                                    <span>
                                            <Input
                                                style={{ width: '80%', marginLeft: '10px' }}
                                                size='small'
                                            />
                                        </span>
                                    </Col>
                                    <Col style={{ fontWeight: 'bold', marginLeft: '-2%' }} span={4}>
                                        THB
                                    <span>
                                            <Input
                                                style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                size='small'
                                            />
                                        </span>
                                    .00
                                </Col>
                                    <Col style={{ fontWeight: 'bold', marginLeft: '-2%' }} span={4}>
                                        MAX.
                                     <span>
                                            <Input
                                                style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                size='small'
                                            />
                                        </span>
                                    PAX
                                </Col>
                                </Row>
                            </div>
                            {
                                this.props.addSubTitleTicket[index].map((item, i) => {
                                    return (
                                        <div key={`ticketSubTitle${i}`} style={{ marginBottom: '8px' }}>
                                            <Row>
                                                <Col style={{ fontWeight: 'bold', marginLeft: '10%' }} span={12}>
                                                    Subtitle
                                    <span>
                                                        <Input
                                                            style={{ width: '80%', marginLeft: '10px' }}
                                                            size='small'
                                                        />
                                                    </span>
                                                </Col>
                                                <Col style={{ fontWeight: 'bold', marginLeft: '-2%' }} span={4}>
                                                    THB
                                                <span>
                                                        <Input
                                                            style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                            size='small'
                                                        />
                                                    </span>
                                                .00
                                            </Col>
                                                <Col style={{ fontWeight: 'bold', marginLeft: '-2%' }} span={4}>
                                                    MAX.
                                                <span>
                                                        <Input
                                                            style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                            size='small'
                                                        />
                                                    </span>
                                                PAX
                                            </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                            <Row style={{ textAlign: 'right', marginRight: '21%', marginTop: '1%' }}>
                                <Button
                                    onClick={() => {
                                        if (this.props.addSubTitleTicket[index].length <= 28) {
                                            this.props.addSubTitleTicketFunction(index)
                                        }
                                    }}
                                    style={{ width: '10%', minWidth: '74px' }}
                                    type="dashed"
                                    size="small"
                                    icon="plus-circle"
                                    >
                                    Add({(this.props.addSubTitleTicket[index].length+1)})/30)
                                </Button>
                            </Row>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default TicketCreator