import React, { Component } from 'react'
import { Button, Radio, Input, Row, Divider } from 'antd'
import TicketCreator from './ticket'
import GoodsSouvenirCreator from './goods-souvenir'
import DeliveryCreator from './delivery'

const Temp = {
    display: 'block',
    color: '#8C8C8C',
}

const radioButtonStyleBox = {
    width: '100%',
    height: '110px',
    textAlign: 'center',
    color: '#8C8C8C',
}

const radioStyleRight = {
    height: '30px',
    lineHeight: '30px',
    position: 'absolute',
    marginLeft: '2%',
    color: '#8C8C8C',
}

const radioStyleLeft = {
    height: '30px',
    lineHeight: '30px',
    color: '#8C8C8C',
}

const radioStyle_inner = {
    display: 'inline',
    color: '#8C8C8C',
}

const radioStyle_inner_2 = {
    marginTop: '-10px',
    marginLeft: '23px',
    color: '#8C8C8C',
}

const radioButtonStyle = {
    height: '100%',
    width: '42%',
    marginLeft: '1%',
    marginRight: '2%',
    color: '#8C8C8C',
    borderRadius: '7px',
    borderWidth: '3px'
}

const radioButtonStyle_inner = {
    marginTop: '30px',
    color: '#8C8C8C',
}

class TicketAndGoodsWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenLightBox: false,
            photoIndex: 0,
            id: '',
            total_amount: '0',
            joined_count: 0,
            invitation_count: 0,

            invitationLoading: false,
            invitationOrderBy: null,
            invitationTotalCount: 0,
            invitations: [],
            invitationPageSize: 20,
            ticketType: '',
            showGoodsConfig: false,
            addSubTitleTicket: [[]],
            addTitleTicket: [1],
            addSubTitleGoodsandSouvenir: [[]],
            addDescriptionGoodsandSouvenir: [1],
            addSubTitleDelivery: [[]],
            addDescriptionDelivery: [1],
        }
        this.selectTicketType = this.selectTicketType.bind(this)
        this.showGoodsConfig = this.showGoodsConfig.bind(this)
        this.addSubTitleTicket = this.addSubTitleTicket.bind(this)
        this.addTitleTicket = this.addTitleTicket.bind(this)
        this.addSubTitleGoodsandSouvenir = this.addSubTitleGoodsandSouvenir.bind(this)
        this.addDescriptionGoodsandSouvenir = this.addDescriptionGoodsandSouvenir.bind(this)
        this.addSubTitleDelivery = this.addSubTitleDelivery.bind(this)
        this.addDescriptionDelivery = this.addDescriptionDelivery.bind(this)
    }
    // ------------------ function Section ---------------------- //

    selectTicketType = (event) => {
        this.setState({ ticketType: event.target.value })
    }
    showGoodsConfig = (key) => {
        if (key === '4') {
            this.setState({ showGoodsConfig: true })
        } else {
            this.setState({ showGoodsConfig: false })
        }
    }
    addSubTitleTicket = (index) => {
        this.state.addSubTitleTicket[index].push(1)
        this.setState({ addSubTitleTicket: this.state.addSubTitleTicket })
    }
    addTitleTicket = () => {
        this.state.addTitleTicket.push(1)
        this.state.addSubTitleTicket.push([])
        this.setState({ addTitleTicket: this.state.addTitleTicket })
    }
    addSubTitleGoodsandSouvenir = (index) => {
        this.state.addSubTitleGoodsandSouvenir[index].push(1)
        this.setState({ addSubTitleGoodsandSouvenir: this.state.addSubTitleGoodsandSouvenir })
    }
    addDescriptionGoodsandSouvenir = () => {
        this.state.addDescriptionGoodsandSouvenir.push(1)
        this.state.addSubTitleGoodsandSouvenir.push([])
        this.setState({ addDescriptionGoodsandSouvenir: this.state.addDescriptionGoodsandSouvenir })
    }
    addSubTitleDelivery = (index) => {
        this.state.addSubTitleDelivery[index].push(1)
        this.setState({ addSubTitleDelivery: this.state.addSubTitleDelivery })
    }
    addDescriptionDelivery = () => {
        this.state.addDescriptionDelivery.push(1)
        this.state.addSubTitleDelivery.push([])
        this.setState({ addDescriptionDelivery: this.state.addDescriptionDelivery })
    }

    // ------------------ function Section ---------------------- //
    render() {
        return (
            <div style={{ textAlign: 'center', backgroundColor: '#EDEDF0' }}>
                <div style={{ width: '100%', display: 'inline-block' }}>
                    <div style={{ background: `white`, border: `0px solid gray`, padding: `20px 20px 40px`, marginBottom: `20px` }}>
                        <h1 style={{ marginTop: '30px', marginBottom: '40px', color: '#8C8C8C', textAlign: 'left' }}>Select ticket Type</h1>
                        <Radio.Group style={Temp} buttonStyle="solid" onChange={this.selectTicketType}>
                            <div>
                                <div style={radioButtonStyleBox}>
                                    <Radio.Button style={radioButtonStyle} value="Pay">
                                        <h2 style={radioButtonStyle_inner}>Pay to join</h2>
                                    </Radio.Button>
                                    <Radio.Button style={radioButtonStyle} value="Free">
                                        <h2 style={radioButtonStyle_inner}>Free</h2>
                                    </Radio.Button>
                                </div>
                            </div>
                        </Radio.Group>
                        <h1 style={{ marginTop: '30px', marginBottom: '40px', color: '#8C8C8C', textAlign: 'left' }}>Category of Event Entry</h1>
                        <Radio.Group style={{ width: '100%', textAlign: 'left', paddingLeft: '18%' }}>
                            <Radio style={radioStyleLeft} value={1}>
                                <h3 style={radioStyle_inner}>FOR SINGLE PARTICIPANT</h3>
                                <br />
                                <div style={radioStyle_inner_2}>
                                    Number of people attending the event
                            <span>
                                        <Input
                                            style={{ width: '50px', marginLeft: '10px' }}
                                            size='small'
                                        />
                                    </span>
                                </div>
                            </Radio>
                            <Divider style={{ width: '2px', height: '70px', marginTop: '-50px', marginLeft: '5%' }} type='vertical' />
                            <Radio style={radioStyleRight} value={2}>
                                <h3 style={radioStyle_inner}>FOR TEAM MEMBER</h3>
                                <br />
                                <div style={radioStyle_inner_2}>
                                    Number of people attending the event
                            <span>
                                        <Input
                                            style={{ width: '50px', marginLeft: '10px' }}
                                            size='small'
                                        />
                                    </span>
                                </div>
                                <div style={radioStyle_inner_2}>
                                    Number of people per team
                            <span>
                                        <Input
                                            style={{ width: '50px', marginLeft: '76px', marginTop: '11px' }}
                                            size='small'
                                        />
                                    </span>
                                </div>
                            </Radio>
                        </Radio.Group>
                    </div>
                    {/* ------------------ Ticket and Goods Detail Section ------------------ */}
                    <div style={{ color: '#8C8C8C' }}>
                        <div style={{ background: `white`, border: `0px solid gray`, padding: `20px 20px 40px`, marginBottom: `20px` }}>
                            <h1 style={{ width: '100%', textAlign: 'left', marginBottom: '40px', color: '#8C8C8C' }}>Ticket Details</h1>
                            {/* ------------------ Ticket create Section ------------------ */}
                            <TicketCreator
                                addTitleTicket={this.state.addTitleTicket}
                                addSubTitleTicket={this.state.addSubTitleTicket}
                                addSubTitleTicketFunction={this.addSubTitleTicket}
                            >
                            </TicketCreator>
                            <Row style={{ textAlign: 'Left', marginRight: '21%', marginTop: '1%' }}>
                                <Button
                                    onClick={() => { this.addTitleTicket() }}
                                    style={{ width: '10%', minWidth: '120px', marginLeft: '10%' }}
                                    type="primary"
                                    size="default">Add more title
                                </Button>
                            </Row>
                        </div>
                        {/* ------------------ Ticket create Section ------------------ */}
                        {/* ------------------ Goods&Souvenir create Section ------------------ */}
                        <div style={{ background: `white`, border: `0px solid gray`, padding: `20px 20px 40px`, marginBottom: `20px` }}>
                            <h1 style={{ width: '100%', textAlign: 'left', color: '#8C8C8C', marginBottom: '40px' }}>Goods & Souvenir</h1>
                            <GoodsSouvenirCreator
                                addDescriptionGoodsandSouvenir={this.state.addDescriptionGoodsandSouvenir}
                                addSubTitleGoodsandSouvenir={this.state.addSubTitleGoodsandSouvenir}
                                addSubTitleGoodsandSouvenirFunction={this.addSubTitleGoodsandSouvenir}
                            >
                            </GoodsSouvenirCreator>
                            <Row style={{ textAlign: 'Left', marginRight: '21%', marginTop: '1%' }}>
                                <Button
                                    onClick={() => { this.addDescriptionGoodsandSouvenir() }}
                                    style={{ width: '20%', minWidth: '166px', marginLeft: '10%' }}
                                    type="primary"
                                    size="default">
                                    Add more description
                                </Button>
                            </Row>
                            {/* ------------------ Goods&Souvenir create Section ------------------ */}
                            {/* ------------------ Delivery create Section ------------------ */}
                            <h1 style={{ width: '100%', textAlign: 'left', color: '#8C8C8C', marginBottom: '40px', marginTop: '40px' }}>Delivery</h1>
                            <DeliveryCreator
                                addDescriptionDelivery={this.state.addDescriptionDelivery}
                                addSubTitleDelivery={this.state.addSubTitleDelivery}
                                addSubTitleDeliveryFunction={this.addSubTitleDelivery}
                            >
                            </DeliveryCreator>
                            <Row style={{ textAlign: 'Left', marginRight: '21%', marginTop: '1%' }}>
                                <Button
                                    onClick={() => { this.addDescriptionDelivery() }}
                                    style={{ width: '20%', minWidth: '166px', marginLeft: '10%' }}
                                    type="primary"
                                    size="default">
                                    Add more description
                                </Button>
                            </Row>
                            {/* ------------------ Delivery create Section ------------------ */}
                        </div>
                        <div style={{ background: `white`, border: `0px solid gray`, padding: `20px 20px 40px`, marginBottom: `20px` }}>
                            <h1 style={{ width: '100%', textAlign: 'left', marginBottom: '40px', color: '#8C8C8C' }}>Ticket and Goods Status</h1>
                            <Radio.Group style={Temp} buttonStyle="solid" onChange={this.selectTicketType}>
                                <div>
                                    <div style={radioButtonStyleBox}>
                                        <Radio.Button style={radioButtonStyle} value="Disable">
                                            <h2 style={radioButtonStyle_inner}>Disable</h2>
                                        </Radio.Button>
                                        <Radio.Button style={radioButtonStyle} value="Enable">
                                            <h2 style={radioButtonStyle_inner}>Enable</h2>
                                        </Radio.Button>
                                    </div>
                                </div>
                            </Radio.Group>
                        </div>
                        <Row style={{ textAlign: 'Right', marginBottom: '2%', marginTop: '1%' }}>
                            <Button
                                //onClick={() => { this.addDescriptionDelivery() }}
                                style={{ width: '7%', marginLeft: '1%' }}
                                size="default">
                                Back
                            </Button>
                            <Button
                                //onClick={() => { this.addDescriptionDelivery() }}
                                style={{ width: '7%', marginLeft: '1%' }}
                                type="primary"
                                size="default">
                                Save
                            </Button>
                        </Row>
                    </div>
                </div>
            </div>
            //{/* ------------------ Ticket and Goods Detail Section ------------------ */ }
        )
    }
}
export default TicketAndGoodsWrapper