import React from 'react'
import s from 'styled-components'
import { toUpperCaseFirst } from '../../utils/string'

const ListViewTransaction = s.div`
  padding:0;
  margin:10px 0 0;
`

const ListViewItems = s.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 100px 30px;
  grid-template-columns: 1fr 100px 30px;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #c7c7c7;
  cursor:pointer;
  
  & > div {
    padding : 10px;
  }
  
  & > div:nth-child(1) > p {
    color: #887979;
  }
  
  & > div:nth-child(2),
  & > div:nth-child(3){
    color:#C14C40;
    font-weight:bold;
  }
  
  & > div:nth-child(2) {
    text-align:right;
    padding-right: 0;
  }
  
  & > div:nth-child(3) {
    text-align:center;
    padding-left: 5px;
  }
`

const onClick = (fn, payload) => fn.bind(null, payload)

/**
 * @return {null}
 */
function ListViewComponent(props) {
  if (props.data.length <= 0) {
    return null
  }
  return (
    <ListViewTransaction>
      {props.data.map((v, i) => (
        <ListViewItems
          key={`ListViewComponent-item-${i}`}
          onClick={onClick(props.onClick, v)}
        >
          <div>
            <h3>{v.receiver_name}</h3>
            <p>{v.receiver_email}</p>
          </div>
          <div>{toUpperCaseFirst(v.status)}</div>
          <div>></div>
        </ListViewItems>
      ))}
    </ListViewTransaction>
  )
}

ListViewComponent.defaultProps = {
  data: [],
  onClick: null,
}

export default ListViewComponent
