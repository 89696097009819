import React, { Component } from 'react';
import { Button, Row, Col, Input, Divider } from 'antd';

class GoodsSouvenirCreator extends Component {
    render() {
        return (
            <div>
                {this.props.addDescriptionGoodsandSouvenir.map((data, index) => {
                    return (
                        <div key={`DescriptionGoodsandSouvenir${index}`} style={{ marginBottom: '40px' }}>
                            { index != 0 ? <Divider style={{ display: 'block', width: '85%', minWidth: '20%', marginLeft: '3%', marginBottom: '35px' }} orientation='center' /> : null}
                            <Row align="middle" style={{ marginBottom: '8px' }}>
                                <Col style={{ fontWeight: 'bold' }}>Title
                             <span>
                                        <Input
                                            style={{ width: '58%', marginLeft: '10px' }}
                                            size='small'
                                        />
                                        <Button
                                            //onClick={() => { this.addDescriptionGoodsandSouvenir() }}
                                            style={{ width: '15%', minWidth: '120px', marginLeft: '1.35%', color: '#E1AAA4', borderColor: '#E1AAA4' }}
                                            size="small">Upload photo</Button>
                                    </span>
                                </Col>
                            </Row>
                            <div style={{ marginBottom: '8px' }}>
                                <Row>
                                    <Col style={{ fontWeight: 'bold', marginLeft: '10%' }} span={12}>
                                        Subtitle
                                    <span>
                                            <Input
                                                style={{ width: '80%', marginLeft: '10px' }}
                                                size='small'
                                            />
                                        </span>
                                    </Col>
                                    <Col style={{ fontWeight: 'bold', marginLeft: '1%' }} span={4}>
                                        THB
                                    <span>
                                            <Input
                                                style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                size='small'
                                            />
                                        </span>
                                    .00
                                </Col>

                                </Row>
                            </div>
                            {
                                this.props.addSubTitleGoodsandSouvenir[index].map((item, i) => {
                                    return (
                                        <div key={`subTitleGoodsandSouvenir${i}`}style={{ marginBottom: '8px' }}>
                                            <Row>
                                                <Col style={{ fontWeight: 'bold', marginLeft: '10%' }} span={12}>
                                                    Subtitle
                                                    <span>
                                                        <Input
                                                            style={{ width: '80%', marginLeft: '10px' }}
                                                            size='small'
                                                        />
                                                    </span>
                                                </Col>
                                                <Col style={{ fontWeight: 'bold', marginLeft: '-4%' }} span={4}>
                                                    THB
                                                    <span>
                                                        <Input
                                                            style={{ width: '30%', marginLeft: '7%', marginRight: '4%' }}
                                                            size='small'
                                                        />
                                                    </span>
                                                .00
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })
                            }
                            <Row style={{ textAlign: 'right', marginRight: '21%', marginTop: '1%' }}>
                                <Button
                                    onClick={() => {
                                        if (this.props.addSubTitleGoodsandSouvenir[index].length <= 28) {
                                            this.props.addSubTitleGoodsandSouvenirFunction(index)
                                        }
                                    }}
                                    style={{ width: '10%', minWidth: '74px' }}
                                    type="dashed"
                                    size="small"
                                    icon="plus-circle"
                                    >
                                    Add({(this.props.addSubTitleGoodsandSouvenir[index].length + 1)})/30)
                                </Button>
                            </Row>
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default GoodsSouvenirCreator